/** @jsx jsx */
import { jsx, Styled } from 'theme-ui';
import { Link } from 'gatsby';

import paths from '../../utils/paths';
import useCurrentLanguage from '../../hooks/use-current-language';

import Layout from '../../components/Layout';
import Container from '../../components/Container2';

const NotFoundPage = () => {
  const lang = useCurrentLanguage();
  return (
    <Layout
      page="404"
      seo={{
        title: '404',
      }}
    >
      <Container>
        <Styled.h1>Page non trouvée</Styled.h1>
        <Styled.p sx={{ mt: '48px', color: 'muted' }}>
          Oh non! La page que vous recherchez a été retirée ou déplacée.
        </Styled.p>
        <Styled.p sx={{ mt: '32px' }}>
          <Link to={paths.home[lang]} sx={{ color: 'inherit' }}>
            Retour
          </Link>
        </Styled.p>
      </Container>
    </Layout>
  );
};

export default NotFoundPage;
